@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 1000;
  src:
    url('./static/font/Gilroy-Black.woff2') format('woff2') /*Modern browsers*/,
    url('./static/font/Gilroy-Black.ttf') format('truetype') /*Safari, Android, iOS*/;
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src:
    url('./static/font/Gilroy-Medium.woff2') format('woff') /*Modern browsers*/,
    url('./static/font/Gilroy-Medium.ttf') format('truetype') /*Safari, Android, iOS*/;
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  src:
    url('./static/font/Gilroy-Semibold.woff2') format('woff2') /*Modern browsers*/,
    url('./static/font/Gilroy-Semibold.ttf') format('truetype') /*Safari, Android, iOS*/;
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src:
    url('./static/font/Gilroy-Bold.woff2') format('woff2') /*Modern browsers*/,
    url('./static/font/Gilroy-Bold.ttf') format('truetype') /*Safari, Android, iOS*/;
}
